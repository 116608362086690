export const SCREENS = {
  //LOGIN
  WELCOME_TITLE: 'ברוכים הבאים ל - tagbiz',
  EASY_WAY: '',
  OR: 'או',
  PRESS_HERE: 'לחץ כאן',
  CREATE_NEW_ACCOUNT: 'או URL ליצירת חשבון חדש',
  EMAIL_PLACE_HOLDER: 'כתובת המייל שלך',
  EMAIL_ERROR: 'כתובת המייל אינה תקינה',
  PASSWORD_PLACE_HOLDER: 'סיסמא',
  PASSWORD_ERROR: 'סיסמא לא תקינה',
  WRONG_PASSWORD: 'סיסמא לא נכונה',
  NOT_ALLOWED: 'משתמש לא מורשה - אנא פנה לתמיכה',
  SOMETHING_WENT_WRONG: 'משהו לא הלך נכון, נסו שנית מאוחר יותר',
  EMAIL_VERIFY: 'מייל לא אומת',
  EMAIL_NOT_FOUND: 'מייל לא נמצא במערכת, אנא צור חשבון חדש',
  REMEMBER_ME: 'זכור אותי',
  FORGOT_PASSWORD: 'שכחתי סיסמא',
  CONNECT: 'התחבר',
  IF_WITH: 'או באמצעות',
  CONNECT_WITH_GOOGLE: 'התחבר באמצעות גוגל',
  CONNECT_WITH_FACEBOOK: 'התחבר באמצעות פייסבוק',

  //REGISTER
  FIRST_NAME: 'שם פרטי*',
  LAST_NAME: 'שם משפחה*',
  BUSINESS_NAME: 'שם החברה',
  REG_EMAIL_PLACE_HOLDER: 'כתובת המייל שלך*',
  REG_PASSWORD_PLACE_HOLDER: 'סיסמא*',
  PASSWORD_REPEAT_PLACE_HOLDER: 'סיסמא בשנית*',
  CREATE_MY_ACCOUNT: 'צור את החשבון שלי',
  CREATE_TITLE: '30 ימי ניסיון חינם וללא התחייבות!',
  REGISTER_SUB_TITLE: 'הדרך הקלה לחיים קלים ניהול קשרי לקוחות ומסמכים',
  CONNECT_TO_ACCOUNT: 'כבר יש לך חשבון? URL להתחברות',
  ACCEPT_TERMS: '*קראתי ואישרתי את URL ואת URL',
  TERMS_OF_USE: 'תנאי השימוש',
  TERMS_OF_USE_URL: 'https://tagbiz.io/terms-of-use/',
  PRIVACY_POLICY: 'מדיניות הפרטיות',
  PRIVACY_POLICY_URL: 'https://tagbiz.io/privacy-policy/',
  ACCEPT_MAILING:
    'אני רוצה לקבל דיוור ישיר למייל עם חידושים, עידכונים וטיפים לעצמאיים',
  POLICY_ERROR: 'נא לאשר את תנאי השימוש ומדיניות הפרטיות',
  EMAIL_IN_USE: 'כתובת המייל כבר קיימת במערכת',

  //FORGOT_PASSWORD
  FORGOT_PASSWORD_TITLE: 'אופס! שכחת את הסיסמא שלך?',
  RETURN_TO_LOGIN: 'חזור להתחברות',
  LAST_PASS_REMEMBERED: 'הסיסמא האחרונה שאתה זוכר',
  RESET_MY_PASSWORD: 'אפס את הסיסמא שלי',
  FORGOT_PASSWORD_SUB_TITLE:
    'הזן את כתובת המייל שאליו תרצה שנשלח את הסיסמא החדשה שלך',
  // EMAIL_CONFIRMATION
  EMAIL_CONFIRMATION: 'תודה שאישרת את המייל!',
  EMAIL_CONFIRMATION_ERROR: 'חלה בעיה באישור הקוד, אנא פנה אלינו',
  EMAIL_CONFIRMATION_SENT_TITLE: 'הוספת חשבון בוצעה בהצלחה',
  EMAIL_CONFIRMATION_SENT_SUB_TITLE:
    'שלחנו לך מייל לאישור החשבון, אנא בדוק את תיבת הדואר שלך',
  //RESET_PASSWORD
  RESET_PASSWORD_TITLE: 'בחירת סיסמה חדשה',
  RESET_PASSWORD_SUB_TITLE:
    'הזן את הסיסמא החדשה שאיתה תרצה להתחבר לחשבון האישי שלך במערכת Tagbiz ',
  RESET_PASSWORD_TEXT_1:
    'הוראת איפוס הסיסמא שלך נשלחו למייל שלך {X} , מייל זה יגיע אלייך ממש בזמן הקרוב',
  RESET_PASSWORD_TEXT_2:
    'אם לא תקבל את המייל בשעה הקרובה, בבקשה בדוק את דואר ה״ספאם״ או שניתן פשוט ללחוץ כאן ולקבל הוראות מלאות',
  RESET_PASSWORD_TEXT_3:
    'לא בטוח איזה מייל הזנת בהרשמה ל Tagbiz? , אין לך גישה למייל זה? צור איתנו קשר ונשמח לסייע בכל שאלה!',
  RESET_PASSWORD_TEXT_4: 'צוות Tagbiz.',
  INSERT_NEW_PASSWORD: 'סיסמה חדשה*',
  REPEAT_NEW_PASSWORD: 'אישור סיסמה*',
  RESET_PASSWORD_ERROR: 'סיסמאות לא תואמות',
  RESET_BTN: 'עדכון סיסמה',
  RESET_PASSWORD_FINISHED: 'סיסמתך שונתה בהצלחה',

  //ERROR_MODAL
  ERROR_MODAL_TITLE: 'שגיאת מערכת',
  ERROR_MODAL_SUB_TITLE: 'אנא נסו מאוחר יותר',
  ERROR_MODAL_CLOSE: 'סגור',

  //NOTICE_MODAL
  NOTICE_MODAL_TITLE: 'השינוי נשמר בהצלחה!',
  NOTICE_MODAL_SUB_TITLE: 'מיד תועבר בחזרה',

  //APPROVAL_MODAL
  APPROVAL_MODAL_TITLE: 'האם אתה בטוח שברצונך לשלוח את החוזה?',
  APPROVAL_MODAL_SUB_TITLE: '',
  //LOADER_MODAL
  LOADER_MODAL_TITLE: 'שולח חוזה, נא לא לצאת....',
  LOADER_MODAL_SUB_TITLE: 'מיד תועבר בחזרה',

  //UPLOAD_FILE_MODAL
  UPLOAD_FILE_MODAL_TITLE: 'שולח חוזה, נא לא לצאת....',
  UPLOAD_FILE_MODAL_SUB_TITLE: 'מיד תועבר בחזרה',

  //SEND_CONTRACT_MODAL
  SEND_CONTRACT_MODAL_TITLE: 'איך תרצו לשלוח את החוזה',
  SEND_CONTRACT_MODAL_OPTION_0: 'שלחו באמצעות הודעת וואטסאפ למספר: ',
  SEND_CONTRACT_MODAL_OPTION_1: 'שלחו באמצעות מייל לכתובת: ',
  SEND_CONTRACT_MODAL_CLOSE: 'ביטול',
  SEND_CONTRACT_MODAL_SEND: 'שלח',

  //TERMS_MODAL
  REMOVE_SERVICE_TITLE: 'למחוק את השירות',
  REMOVE_SERVICE_SUB_TITLE_1:
    'האם אתה בטוח שברצונך למחוק את השירות ״צילום מגנטים״?',
  REMOVE_SERVICE_SUB_TITLE_2: 'לאחר המחיקה לא יהיה ניתן לצפות בו או לשחזרו',
  REMOVE_SERVICE_RIGHT_BTN: 'מחק',
  REMOVE_SERVICE_LEFT_BTN: 'אל תמחק',
  DISCARD_CHANGES_TITLE: 'למחוק את השינויים?',
  DISCARD_CHANGES_SUB_TITLE_1: 'האם אתה בטוח שברצונך לצאת ללא שמירת הנתונים״?',
  DISCARD_CHANGES_SUB_TITLE_2: 'לאחר היציאה לא יהיה ניתן לצפות בו או לשחזרו',
  DISCARD_CHANGES_RIGHT_BTN: 'אישור',
  DISCARD_CHANGES_LEFT_BTN: 'ביטול',
  LOG_OUT_TITLE: 'האם אתה בטוח שברצונך להתנתק',
  LOG_OUT_RIGHT_BTN: 'התנתק',
  LOG_OUT_LEFT_BTN: 'הישאר במערכת',

  //AddCustomer
  ADD_CUSTOMER_USER_UPDATED: 'הלקוח עודכן בהצלחה',
  ADD_CUSTOMER_USER_CREATED: 'הלקוח נוסף בהצלחה',
  NO_TASKS_RESULTS: 'אין משימות עבור לקוח זה',
  TASKS_ADD_CUSTOMER: 'שמרו את הלקוח על מנת להוסיף משימות',
  FIELD_FIRST_NAME: 'שם',
  FIELD_LAST_NAME: 'שם נוסף',
  FIELD_EMAIL: 'כתובת אימייל',
  FIELD_PHONE: 'מספר טלפון',
  FIELD_ID: 'תעודת זהות',
  FIELD_OTHER_ID: 'תעודת זהות נוספת',
  FIELD_ADDRESS: 'כתובת',
  FIELD_OTHER_PHONE: 'טלפון נוסף',
  FIELD_CUSTOMER_NOTE: 'הערת לקוח',
  FIELD_CUSTOMER_STATUS: 'סטטוס לקוח',
  FIELD_CAME_FROM: 'מקור הגעה',
  FIELD_CAME_FROM_PLACE_HOLDER: 'בחר את מקור הלקוח',
  FIELD_CAME_FROM_ARRAY: [
    'פייסבוק',
    'אינסטגרם',
    'אתר אינטרנט',
    'המלצה של ספק',
    'המלצה של לקוח/ה',
    'אחר',
  ],
  SAVE_CUSTOMER: 'שמור',
  DELETE_CUSTOMER: 'מחק',
  DELETE_CUSTOMER_TITLE: 'האם אתה בטוח שברצונך למחוק את הלקוח?',
  DELETE_CUSTOMER_RIGHT_BTN: 'אישור',
  DELETE_CUSTOMER_LEFT_BTN: 'ביטול',
  SEND_NOTIFICATION_EMAIL: 'שלח התראה באיימל',
  SEND_NOTIFICATION_WHATSAPP: 'שלח התראה בוואטסאפ',
  ACTIVE_WHATSAPP_WEB: 'חיבור לוואטסאפ',

  //GENERAL
  BTN_NEW_CUSTOMER: 'לקוח חדש',
  BTN_SEND_DOC_TO_CUSTOMER: 'שלח מסמך ללקוח',
  BTN_CUSTOMER_STATUS: 'סטטוס לקוח',
  RETURN: 'חזרה',

  //AddFiles
  ADD_FILES_TO_CUSTOMER: 'צרף קבצים ללקוח',
  ADD_FILES_NO_FILES_ATTACHED: 'לא צורפו קבצים',
  ADD_FILES_ADD_CUSTOMER: 'שמור את הלקוח על מנת להוסיף קבצים',
  DRAG_DROP: 'גרור לכאן את הקבצים שלך',
  DROP_HERE: 'זרוק את הקבצים שלך כאן',
  ADD_FILES_BTN: 'בחר קובץ',
  ADD_FILES_FILES_WEIGHT: 'קבצים עד משקל 500mb לכל היותר',
  REMOVE_FILE_APPROVAL: 'האם אתם בטוחים שברצונכם למחוק את הקובץ?',

  //CustomerList
  NEW_CUSTOMER: 'לקוח חדש',
  SEND_DOC_TO_CUSTOMER: 'שליחת מסמך',
  RESET_DOC_TO_CUSTOMER: 'איפוס מסמך',
  EDIT_DOC_TO_CUSTOMER: 'עריכת מסמך',
  SAVE_DOC_TO_CUSTOMER: 'שמירת מסמך',
  SEND_MEMORY_TO_CUSTOMER: 'שלח התראה',
  CUSTOMER_STATUS: 'סטטוס לקוח',
  CUSTOMER_FILTER_BY: 'סנן לפי',
  SEARCH_CUSTOMER_DETAILS: 'חפש נתוני לקוח',
  SEND_CONTRACT_TO_CUSTOMER_SUCCESS: 'החוזה נשלח ללקוח',
  DELETE_CUSTOMER_C_LIST: 'מחק לקוח',
  DELETE_CUSTOMERS_TITLE: 'האם אתם בטוחים שברצונכם למחוק את הלקוחות המסומנים?',

  // CALENDAR
  EVENTS_BETWEEN_DATES: 'רשימת אירועים בין התאריכים: ',
  EVENT_IN_DATE: 'רשימת אירועים בתאריך: ',
  WEEKLY_DATES_TITLE: 'אירועים לשבוע הקרוב',
  WEEKLY_DATES_SUB_TITLE: 'יש לך 8 אירועים השבוע',

  //EventsList
  NEW_EVENT: 'לקוח חדש',
  SEND_DOC_TO_CUSTOMER_EVENTS: 'שלח מסמך ללקוח',
  SEND_MEMORY_TO_CUSTOMER_EVENTS: 'שלח התראה',
  EVENT_STATUS: 'סטטוס שירות',
  EVENTS_FILTER_BY: 'סנן לפי',
  SEARCH_EVENT_DETAILS: 'חיפוש פרטי אירוע',
  EMPTY_EVENT_LIST: ' אין אירועים השבוע',
  ADD_EVENT: 'הוסף אירוע חדש',

  //Settings
  BUSINESS_DETAILS_TAB: 'פרטי החברה',
  DOCUMENTS_TAB: 'מסמכים',
  NOTIFICATIONS_TAB: 'התראות',
  DESIGN_PATTERNS_TAB: 'תצוגה מקדימה',
  PERMANENTS_FIELDS_TAB: 'שדות קבועים',
  MAILS_TAB: 'מיילים',
  TAGS_TAB: 'תגיות',
  BUSINESS_DETAILS_TITLE: 'פרטי העסק',
  BUSINESS_NAME_LABEL: 'שם העסק',
  BUSINESS_NAME_PLACE_HOLDER: 'שם העסק שלך',
  BUSINESS_OWNER_NAME_LABEL: 'שם בעל העסק',
  BUSINESS_OWNER_NAME_PLACE_HOLDER: 'הזן את שם בעל העסק',
  BUSINESS_PHONE_NUMBER_LABEL: 'מספר טלפון',
  BUSINESS_PHONE_NUMBER_PLACE_HOLDER: 'מספר הטלפון של העסק',
  BUSINESS_OTHER_PHONE_NUMBER_LABEL: 'מספר טלפון נוסף',
  BUSINESS_OTHER_PHONE_NUMBER_PLACE_HOLDER: 'מספר טלפון נוסף',
  BUSINESS_NUMBER_LABEL: 'מספר ח.פ / ע.מ',
  BUSINESS_NUMBER_PLACE_HOLDER: 'הזן את מספר העוסק מורשה / פטור',
  BUSINESS_EMAIL_LABEL: 'כתובת מייל',
  BUSINESS_EMAIL_PLACE_HOLDER: 'הזן את כתובת המייל של החברה',
  BUSINESS_TYPE_LABEL: 'סוג החברה',
  BUSINESS_TYPE_PLACE_HOLDER: 'בחר את סוג החברה שלך',
  BUSINESS_ADDRESS_LABEL: 'כתובת העסק',
  BUSINESS_ADDRESS_PLACE_HOLDER: 'כתובת בית העסק שלך',
  BUSINESS_BANK_LABEL: ' פרטי חשבון',
  BUSINESS_BANK_DETAILS: 'פרטי חשבון בנק',
  BUSINESS_BANK_PLACE_HOLDER: 'מספר בנק',
  BUSINESS_BANK_BRANCH_PLACE_HOLDER: 'מספר סניף',
  BUSINESS_BANK_NUMBER_PLACE_HOLDER: 'מספר חשבון בנק',
  BUSINESS_WEBSITE_PLACE_HOLDER: 'https://',
  BUSINESS_WEBSITE_LABEL: 'קישור לאתר אינטרנט',
  BUSINESS_FACEBOOK_WEBSITE_LABEL: 'קישור לעמוד פייסבוק',
  BUSINESS_INSTAGRAM_WEBSITE_LABEL: 'קישרו לעמוד אינסטגרם',
  BUSINESS_YOUTUBE_VIMEO_LABEL: 'קישור לעמוד יוטיוב/ווימאו',
  BUSINESS_CREATE_WEBHOOK: 'יצירת WEBHOOK',
  BUSINESS_SIGNATURE: 'חתימת העסק',
  BUSINESS_LOGO: 'לוגו העסק',
  DESIGN_PATTERNS_TAB_TITLE: 'תצוגה מקדימה',
  DESIGN_PATTERNS_CONTRACT_TAB: 'חוזה',
  DESIGN_PATTERNS_BID_TAB: 'הצעת מחיר',
  DESIGN_PATTERNS_DEMAND_TAB: 'דרישת תשלום',
  PERMANENTS_FIELDS_TITLE: 'שדות קבועים',
  PERMANENTS_FIELDS_SUB_TITLE:
    'סמן וערוך את השדות שתרצה שיופיעו בחוזה הקבוע שלך',

  //Statuses
  STATUS_CUSTOMER_NEW: 'חדש',
  STATUS_CUSTOMER_NOT_RELEVANT: 'לא רלוונטי',
  STATUS_CUSTOMER_CLOSE: 'סגור',
  STATUS_EVENTS_NEW: 'חדש',
  STATUS_EVENTS_QUETE_SENT: 'נשלחה הצעת מחיר',
  STATUS_EVENTS_SIGNED: 'חוזה נחתם',
  STATUS_EVENTS_CLOSE: 'סגור',
  STATUS_EVENTS_CANCEL: 'בוטל',

  //PermanentsFields
  TERMS_OF_ENGAGEMENT: 'תנאי התקשרות',
  GENERAL_CONDITIONS: 'תנאים כללים',
  TERMS_OF_PAYMENT: 'תנאי תשלום',
  CANCELLATION_POLICY: 'מדיניות ביטול',
  VARIANCE: 'שונות',
  PERMANENT_FIELDS_UPDATE: 'השדות שונו בהצלחה',
  PERMANENT_FIELDS_GENERAL: 'כללי',
  PERMANENT_FIELDS_EVENTS: 'נספחים לפי סוג שירות',
  PERMANENT_FIELDS_MAIL: 'מיילים',

  //Contract
  CONTRACT_UPDATE: 'עדכן',
  CONTRACT_EDIT: 'ערוך',
  CONTRACT_SAVE_AND_UPDATE: 'שמור ועדכן',
  CONTRACT_DOWNLOAD: 'הורדה',
  CONTRACT_TEMPLATE_OVERVIEW: 'תצוגה מקדימה',
  CONTRACT_LOGO_SIZE: 'גודל הלוגו',
  CONTRACT_LOGO_DIRECTION: 'התאמת הלוגו',
  CONTRACT_CHOSE_COLOR: 'בחר צבע:',
  PEEK_ON_CATALOG: 'צפה בקטלוג דוגמאות',
  CONTRACT_UPDATE_SUCCESSFULLY: 'החוזה עודכן בהצלחה',

  //Main Content

  SOON: 'בקרוב!',
  CONTROL_PANEL: 'לוח בקרה',
  MY_CUSTOMERS: 'הלקוחות שלי',
  MY_LEADS: 'הלידים שלי',
  MY_EVENTS: 'האירועים שלי',
  MY_CALENDAR: 'היומן שלי',
  MY_TASKS: 'המשימות שלי',
  SETTINGS: 'הגדרות המערכת',
  CONTACT_US: 'צור איתנו קשר',

  //Control Panel
  NEW_CUSTOMERS: 'לקוחות חדשים',
  APPROVAL_SERVICE: 'שירות אושר',
  CONTRACTS_SIGNED: 'חוזים חתומים התקבלו',
  CONTRACTS_PENDING: 'חוזים שממתינים',

  //CustomerPdf
  SEND_SIGNED_CONTRACT: 'שלח',
  SIGN_ON_CONTRACT: 'חתום על החוזה',
  CUSTOMER_PDF_ERROR: 'אופס! המסמך שחיפשת כבר לא זמין במערכת',
  CUSTOMER_PDF_CONTACT: 'צור קשר',
  CUSTOMER_PDF_TRY_REFRESH:
    'נסו לרענן את העמוד או ליצור קשר עם בעל העסק לקבלת מסמך חדש',

  //ContractReview
  INIT_CONTRACT_MODAL_TITLE: 'האם אתה בטוח שברצונך לאפס את החוזה?',
  CONTRACT_REVIEW_FIELDS_TITLE: 'סמנו וערכו את הטקסטים לחוזה לקוח',
  CONTRACT_LOADING_TEXT_TITLE: 'החוזה שלך בדרך...',
  CONTRACT_LOADING_TEXT_SUB_TITLE: 'עוד קצת סבלנות והוא כבר כאן',
  CONTRACT_SENT_TO_CUSTOMER: 'החוזה נשלח ללקוח',

  //Notifications Tab

  NOTIFICATION_NAME: 'שם ההתראה',
  NOTIFICATION_NAME_PLACEHOLDER: 'רשמו את שם המסמך',
  NOTIFICATION_DESC: 'תיאור ההתראה',
  NOTIFICATION_DESC_PLACEHOLDER: 'תיאור קצר של המסמך (לא חובה)',
  NOTIFICATION_ADD_FILE: 'צירוף קובץ',
  NOTIFICATION_FILES: 'קבצים מצורפים',
  NOTIFICATION_ADD_FILE_TITLE:
    'זה המקום לצרף קובץ טקסט או תמונה בפורמט png,jpg,pdf להודעה שלכם',
  NOTIFICATION_ADD_FILE_SUB_TITLE: 'בחר קובץ או גרור לכאן',
  NOTIFICATION_MAIL_CONTENT_TITLE: 'תוכן ההתראה במייל',
  NOTIFICATION_MAIL_HEADER: 'כותרת המייל',
  NOTIFICATION_MAIL_HEADER_PLACEHOLDER:
    'רשמו את הכותרת של המייל שישלח עם המסמך',
  NOTIFICATION_MAIL_BODY: 'גוף המייל',
  NOTIFICATION_MAIL_BODY_PLACEHOLDER: 'רשמו את תוכן גוף המייל שישלח עם המסך',
  NOTIFICATION_CHECKBOX_CONTACT: 'כפתור עם קישור ליצירת קשר',
  NOTIFICATION_CHECKBOX_WEBSITE_LINK: 'כפתור עם קישור לאתר אינטרנט שלכם',
  NOTIFICATION_WHATSAPP_CONTENT_TITLE: 'תוכן ההתראה בוואטסאפ',
  NOTIFICATION_WHATSAPP_BODY: 'גוף ההודעה',
  NOTIFICATION_WHATSAPP_BODY_PLACEHOLDER:
    'רשמו את תוכן גוף ההודעה שתשלח עם המסמך שלכם בוואטסאפ',
  NOTIFICATION_SAVE_AND_CLOSE: 'שמור וסגור',
  CREATE_NOTIFICATION_TABLE_TITLE: 'שם ההתראה',
  CREATE_NOTIFICATION_NEW_NOTIFICATION_BUTTON: 'צור התראה חדשה',
  CREATE_NOTIFICATION_EDIT: 'מחיקה/עריכה',
  CREATE_NOTIFICATION_TITLE: 'התראות',
  CREATE_NOTIFICATION_SUCCESS: 'ההתראה נוצרה בהצלחה',
  DELETE_NOTIFICATION_SUCCESS: 'ההתראה נמחקה בהצלחה',
  DELETE_NOTIFICATION_FILE_SUCCESS: 'הקובץ נמחק בהצלחה',
  UPDATE_NOTIFICATION_SUCCESS: 'ההתראה עודכנה בהצלחה',
};
