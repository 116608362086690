import { template } from 'lodash';

export const METHOD = {
  GET: 'GET',
  POST: 'post',
};

export const ROUTES = {
  POST: {
    LOGIN: '/login',
    SIGN_UP: '/signup',
    FORGOT_PASSWORD: '/forget',
    INSERT_CUSTOMER: '/customers',
    RESET_PASSWORD: '/reset',
    UPLOAD_FILE: template('/customers/${customerId}/files'),
    UPDATE_BUSINESS_SETTINGS: '/users/business',
    INSERT_TASK: template('/customers/${customerId}/tasks'),
    UPDATE_TASK: template('/customers/${customerId}/tasks/${taskId}'),
    UPDATE_CONTRACT_FIELDS: '/users/contracts/fields',
    UPDATE_BUSINESS_SIGNATURE: 'users/business/signature',
    UPDATE_BUSINESS_LOGO: 'users/business/logo',
    UPDATE_CONTRACT: '/users/contracts',
    UPDATE_EVENT_STATUS: template('/customers/events/${eventId}'),
    UPDATE_CONTRACT_FIELDS_BY_CUSTOMER: template(
      '/customers/${customerId}/contracts/fields',
    ),
    RESET_CONTRACT_PDF: template('/customers/${customerId}/contracts/init'),
    SEND_CONTRACT_TO_CUSTOMER: template(
      '/customers/${customerId}/contracts/send',
    ),

    SEND_SIGNED_CONTRACT_PDF: template('contracts/${token}'),
    CREATE_NOTIFICATION: '/users/business/notifications',
    UPLOAD_FILE_TO_NOTIFICATION: template(
      '/users/business/notifications/file/${notificationId}',
    ),
    UPDATE_NOTIFICATION: template(
      '/users/business/notifications/${notificationId}',
    ),
    SEND_ALERT_TO_CUSTOMER: template(
      '/customers/${customerId}/notifications/send',
    ),
  },
  GET: {
    LOGOUT: '/users/logout',
    CONFIRM: '/confirm',
    DASHBOARD: '/users/dashboard',
    GET_BUSINESS_SERVICES: '/users/service',
    GET_COSTUMERS: '/customers',
    GET_CUSTOMER: template('/customers/${customerId}'),
    GET_EVENTS: '/users/events',
    GET_BUSINESS_SETTINGS: '/users/business',

    GET_GOOGLE_EVENTS_PERIOD: (
      startDate: number,
      endDate: number,
    ): string => `api/v1/users/google-events?start=${startDate}&end=${endDate}
    `,
    GET_EVENTS_PERIOD: (startDate: number, endDate: number): string =>
      `/users/events?start=${startDate}&end=${endDate}`,
    GET_CONTRACT: '/users/contracts/pdf',
    GET_CONTRACT_FIELDS: '/users/contracts/fields',
    GET_CONTRACT_FIELDS_BY_CUSTOMER: template(
      '/customers/${customerId}/contracts/fields',
    ),
    GET_CONTRACT_PDF: '/users/contracts/pdf',
    GET_BUSINESS_SIGNATURE: template('/users/business/signature/${fileName}'),
    GET_BUSINESS_LOGO: template('/users/business/logo/${fileName}'),
    GET_CUSTOMER_CONTRACT_DATA: template(
      'customers/${customerId}/contracts/fields',
    ),
    GET_CUSTOMER_CONTRACT_PDF: template(
      'customers/${customerId}/contracts/pdf',
    ),
    GET_CUSTOMER_CONTRACT_PDF_TEST: template(
      'https://www.africau.edu/images/default/sample.pdf',
    ),
    GET_CUSTOMER_CONTRACT_DATA_BY_KEY: template('contracts/${token}'),
    GET_WEBHOOK_API_KEY: template(
      '/users/business/apiKey?serviceType=${serviceName}',
    ),
    GET_NOTIFICATIONS: '/users/business/notifications',
    GET_WHATSAPP_STATE: '/users/whatsapp/state',
    GET_WHATSAPP_AUTH: '/users/whatsapp/auth',
    GET_STATUSES: '/users/business/status',
  },
  DELETE: {
    DELETE_TASK: template('/customers/${customerId}/tasks/${taskId}'),
    DELETE_FILE: template('/customers/${customerId}/files/${fileId}'),
    DELETE_CUSTOMER: template('/customers/${customerId}'),
    DELETE_WEBHOOK: template('/users/business/apiKey/${apiKey}'),
    DELETE_NOTIFICATION: template(
      '/users/business/notifications/${notificationId}',
    ),
    DELETE_NOTIFICATION_FILE: template(
      '/users/business/notifications/${notificationId}/file/${fileId}',
    ),
  },
};
